<template data-testid="logbook">
  <div>
    <LogbookDashboard data-testid="logbook-dashboard" />
  </div>
</template>

<script setup>
import { onBeforeUnmount } from 'vue';
import { useLogbookStore } from '../../store/pinia/logbook.store';
import LogbookDashboard from './logbookDashboard/logbook-dashboard.vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const logbookStore = useLogbookStore();
const { trackPage } = amplitudeComposable();

// --- Methods ---
const getLogbookInformation = async () => {
  setInitialDate();
  await getHubList();
  if (logbookStore.hubList.length > 1) {
    await getFirstSelectedHub();
  }
  await getHubEmployees();
  await getVehiclesPlatesByHub();
  await getJourneyGroups();
  trackPage(logbookStore.selectedHub, {
    resourceId: logbookStore.selectedDate,
  });
};

const getHubList = async () => {
  try {
    await logbookStore.getHubList();
  } catch (error) {
    console.log(error);
  }
};

const getFirstSelectedHub = async () => {
  try {
    await logbookStore.getFirstSelectedHub();
  } catch (error) {
    console.log(error);
  }
};

const getHubEmployees = async () => {
  try {
    await logbookStore.getHubEmployees();
  } catch (error) {
    console.log(error);
  }
};

const getVehiclesPlatesByHub = async () => {
  try {
    await logbookStore.getVehiclesPlatesByHub();
  } catch (error) {
    console.log(error);
  }
};

const getJourneyGroups = async () => {
  try {
    await logbookStore.getJourneyGroups();
  } catch (error) {
    console.log(error);
  }
};

const setInitialDate = () => {
  logbookStore.selectedDate = new Date().toISOString().substring(0, 10);
};

//--- Lifecycle Hooks ---
getLogbookInformation();

onBeforeUnmount(() => {
  logbookStore.$reset();
});
</script>
<style lang="scss" scoped></style>
